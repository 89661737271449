import './index.sass';
import Header from '../../components/Header';
import Music from '../../components/music';
import Footer from '../../components/Footer';
import homevideo from '../../asset/imgs/home/homevideo.mp4';
import homeimg from '../../asset/imgs/home/homeimg.png';
import Screen from '../../asset/imgs/home/Screen.png';
import { CSSTransition } from 'react-transition-group';
import './transition.css';

const Home = () => (
  <div>
    <CSSTransition in={true}
      classNames="home-container"
      timeout={500}
      //元素退出时删除DOM元素
      // unmountOnExit={true}
      appear>
  <div className="home-container">
    <Header />
    <Music />
    <div className="Video-box">
      <video controls poster={Screen} src={homevideo} autoPlay loop muted></video>
      <img className="homeimg" src={homeimg} alt=""></img>
    </div>
    <Footer />
  </div>
    </CSSTransition>
  </div>
);

export default Home;
