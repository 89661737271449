
import contentimg1 from '../../asset/imgs/roadmap/contentimg1.png';
import contentimg2 from '../../asset/imgs/roadmap/contentimg2.png';
import contentimg3 from '../../asset/imgs/roadmap/contentimg3.png';
import contentimg4 from '../../asset/imgs/roadmap/contentimg4.png';
import contentimg5 from '../../asset/imgs/roadmap/contentimg5.png';
import contentimg6 from '../../asset/imgs/roadmap/contentimg6.png';
import contentimg7 from '../../asset/imgs/roadmap/contentimg7.png';
import contentimg8 from '../../asset/imgs/roadmap/contentimg8.png';
import contentimg9 from '../../asset/imgs/roadmap/contentimg9.png';
import contentimg10 from '../../asset/imgs/roadmap/contentimg10.png';
const RoadmapDate = {
  roadmap: [
    {
      title: "1:Blind box on sale on the official website",
      imgurl: contentimg1
    }, {
      title: "2:Oni Club established",
      imgurl: contentimg2
    }, {
      title: `3:Launch of "Moon Night" version`,
      imgurl: contentimg3
    }, {
      title: "4:Launch of DAO, where NFT holders can initiate community votes and proposals.",
      imgurl: contentimg4
    }, {
      title: `5:First "full moon" night <br/> &nbsp BAYC has been "bitten"!`,
      imgurl: contentimg5
    }, {
      title: `6:Next Full Moon <br/> &nbsp Who will be "bitten"?`,
      imgurl: contentimg6
    }, {
      title: "7:Soul Staking",
      imgurl: contentimg7
    }, {
      title: "8:Airdrop  <br/> &nbsp For Oni, a drop of Oni and the Oni range of NFTs ",
      imgurl: contentimg8
    }, {
      title: `9:Oni Club Studio <br/> Integrated creation. <br/> Inviting content creators and producers to co-create high quality content in various <br/> categories on the Oni theme. <br/>
      Copyright operation. <br/>
      Helping Oni Holders manage copyright and commercial licensing<br/>
      Marketing and promotion.<br/>
      Support the marketing activities of Oni community creators through exhibitions, LIVE,<br/> SNS, online placement, TV, cinema placement and other online and offline methods.`,
      imgurl: contentimg9
    }, {
      title: "10: Oni Club Camp provides creative and meeting space for creators and teams to support <br/>their creative activities. It is also a place for Oni Club members to meet offline.",
      imgurl: contentimg10
    }, {
      title: "11:Oni Club Captical <br/> Creativity takes a lot of time and money. In order not to let the best talent go unnoticed, <br/> Oni CAPITAL provides financial support to support their creative activities and <br/> development abroad.",
    }
],
  currentBlog: 0
}
export default RoadmapDate;
