import { run } from "concent";
import loadingPlugin from "concent-plugin-loading";
// import { configureRoute } from "react-router-concent";
import * as models from "./models";
// import setupModel from './pages/SetupDemo/_model';

run(models, {
  log: false,
  plugins: [loadingPlugin],
  middlewares: [
    (stateInfo, next) => {
      // console.warn(stateInfo);
      next();
    }
  ],
  isHot: true
});

// configureRoute();
