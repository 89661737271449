import './index.sass';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Maket1 from "../../asset/imgs/about/Maket1.png";
import Maket2 from "../../asset/imgs/about/Maket2.png";
import Maket3 from "../../asset/imgs/about/Maket3.png";
import Maket4 from "../../asset/imgs/about/Maket4.png";
import Maket5 from "../../asset/imgs/about/Maket5.png";
import Maket6 from "../../asset/imgs/about/Maket6.png";
import Maket7 from "../../asset/imgs/about/Maket7.png";
import O from "../../asset/imgs/about/oni-o.jpg";
import N from "../../asset/imgs/about/oni-n.jpg";
import I from "../../asset/imgs/about/oni-i.jpg";
import oni00 from "../../asset/imgs/about/oni00000.jpg";
import oni01 from "../../asset/imgs/about/oni00001.jpg";
import oni02 from "../../asset/imgs/about/oni00002.jpg";
import oni03 from "../../asset/imgs/about/oni00003.jpg";
import oni04 from "../../asset/imgs/about/oni00004.jpg";
import oni05 from "../../asset/imgs/about/oni00005.jpg";
import oni06 from "../../asset/imgs/about/oni00006.jpg";
import oni07 from "../../asset/imgs/about/oni00007.jpg";
import oni08 from "../../asset/imgs/about/oni00008.jpg";
import oni09 from "../../asset/imgs/about/oni00009.jpg";
import oni10 from "../../asset/imgs/about/oni00010.jpg";
import oni11 from "../../asset/imgs/about/oni00011.jpg";
import oni12 from "../../asset/imgs/about/oni00012.jpg";
import oni13 from "../../asset/imgs/about/oni00013.jpg";
import oni14 from "../../asset/imgs/about/oni00014.jpg";
import oni15 from "../../asset/imgs/about/oni00015.jpg";
import oni16 from "../../asset/imgs/about/oni00016.jpg";
import oni17 from "../../asset/imgs/about/oni00017.jpg";
import { CSSTransition } from 'react-transition-group';
import './transition.css';

const imageRows = [
  [
    { frontUrl: N, backUrl: oni00 },
    { frontUrl: O, backUrl: oni01 },
    { frontUrl: oni17, backUrl: oni03 },
    { frontUrl: oni16, backUrl: oni04 },
    { frontUrl: I, backUrl: oni05 },
  ],
  [
    { frontUrl: oni15, backUrl: oni06 },
    { frontUrl: N, backUrl: oni07 },
    { frontUrl: O, backUrl: oni08 },
    { frontUrl: oni14, backUrl: oni09 },
    { frontUrl: I, backUrl: oni10 },
  ],
  [
    { frontUrl: oni02, backUrl: oni05 },
    { frontUrl: N, backUrl: oni12 },
    { frontUrl: O, backUrl: oni04 },
    { frontUrl: I, backUrl: oni14 },
    { frontUrl: oni12, backUrl: oni15 },
  ],
  [
    { frontUrl: oni11, backUrl: oni13 },
    { frontUrl: N, backUrl: oni16 },
    { frontUrl: oni10, backUrl: oni17 },
    { frontUrl: O, backUrl: oni00 },
    { frontUrl: I, backUrl: oni04 },
  ],
]

const ImageCard = ({ frontUrl, backUrl, className = '' }) => {
  return (
    <div className={`image-card ${className}`}>
      <div className="front">
        <img src={frontUrl} alt='' />
      </div>
      <div className="back">
        <img src={backUrl} alt='' />
      </div>
    </div>
  )
}


const { clientWidth } = document.body;
const About = () => {

  return (
    <div>
      <CSSTransition in={true}
        classNames="about-container"
        timeout={500}
        //元素退出时删除DOM元素
        // unmountOnExit={true}
        appear>
        <div className="about-container">
          <Header />
          <div className="Maketplace-content">
            {clientWidth > 750
              ? <div className="Maketplace-imgs">
                {imageRows?.map((row, index) => (
                  <div className='imgs-row' key={index}>
                    {row?.map((item) => (
                      <ImageCard frontUrl={item.frontUrl} backUrl={item.backUrl} key={item.frontUrl} />
                    ))}
                  </div>
                ))}
              </div> : null
            }
            <div className="Maketplace-text">
              <p className="Maketplace-title">ABOUT ONI NFT</p>
              <div className="line-two"></div>
              <p className="Maketplace-dec">Oni contains a collection of 10,000 avatars. Own it and spread it! Let it infect every corner of the meta-universe. Oni is your new identity in the metaverse. Let's stain our hands with blood!</p>
              <div className="Maketplace-text-down">
                <div className="Maketplace-text-left">
                  <img src={Maket1} alt=''></img>
                  <img src={Maket2} alt=''></img>
                  <img src={Maket3} alt=''></img>
                  <img src={Maket4} alt=''></img>
                  <img src={Maket5} alt=''></img>
                  <img src={Maket6} alt=''></img>
                  <img src={Maket7} alt=''></img>
                </div>
                <div className="Maketplace-text-right">
                  <div className="right-box">
                    <p className="right-title">Apparel</p>
                    <p className="right-text">Japanese style originated by OniClub, with a strong sense of cyber future.</p>
                    <div className="right-line"></div>
                  </div>
                  <div className="right-box">
                    <p className="right-title">Mask</p>
                    <p className="right-text">Thousands of ghosts and thousands of faces, which is not to hide their identity, but to reveal themselves.</p>
                    <div className="right-line"></div>
                  </div>
                  <div className="right-box">
                    <p className="right-title">Weapon</p>
                    <p className="right-text">Everyone has a weapon to conquer the metaverse, bloodied or rusted.</p>
                    <div className="right-line"></div>
                  </div>
                  <div className="right-box">
                    <p className="right-title">Hair Style</p>
                    <p className="right-text">A good haircut is the key to stay in style.</p>
                    <div className="right-line"></div>
                  </div>
                  <div className="right-box" >
                    <p className="right-title">Body</p>
                    <p className="right-text">Can you feel that? This body is full of genes that are eager to donate blood.</p>
                    <div className="right-line"></div>
                  </div>
                  <div className="right-box">
                    <p className="right-title">Tooth</p>
                    <p className="right-text">Ready to suck blood?</p>
                    <div className="right-line"></div>
                  </div>
                  <div className="right-box">
                    <p className="right-title">Red Pupil</p>
                    <p className="right-text">Yes, it is Oni!</p>
                    <div className="right-line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </CSSTransition>
    </div>
  )
};

export default About;
