import { useEffect } from "react";
import './index.sass';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import welcome from "../../asset/imgs/story/welcome.png";
import far from "../../asset/imgs/story/far.png";
import middle from "../../asset/imgs/story/middle.png";
import nearly from "../../asset/imgs/story/nearly.png";
import handlegrafyti from "../../asset/imgs/story/handlegrafyti.svg";
import { CSSTransition } from 'react-transition-group';
import './transition.css';
const showAnimationMap = {
  animation2: false,
}
const Story = () => {
  useEffect(() => {
    setTimeout(() => {
      showAnmimation("handlegrafyti", 26, "animation2");
    }, 1000)

    document.body.addEventListener('mousemove', (event) => {
      const { x } = event;
      moveCrowImg(x);
    })
  }, []);

  const moveCrowImg = (x) => {
    const { clientWidth } = document.body;
    const moveX = x - clientWidth;
    const farDom = document.querySelector('.crow-far');
    const nearlyDom = document.querySelector('.crow-nearly');
    const middleDom = document.querySelector('.crow-middle');
    farDom.style.left = `${moveX * 0.05}px`
    middleDom.style.left = `${moveX * 0.15}px`
    nearlyDom.style.left = `${moveX * 0.3}px`
  }
  //debounce函数防抖 防止用户在这个页面不停的左右滑动一直触发这个动画
  const showAnmimation = (id, step, animation) => {
    //动画为ture时 让i=0 从第一个图开始
    showAnimationMap[animation] = true;
    let i = 0;
    //取id里的图
    const priseGrafyti = document.querySelector("#" + id);
    if (!priseGrafyti) {
      return;
    }
    // setInterva是一个实现定时调用的函数，可按照指定的周期（以毫秒计）来调用函数或计算表达式
    const interval = setInterval(() => {
      priseGrafyti.style.left = `${-i * 100}%`
      i = i + 1;
      if (i === step) {
        clearInterval(interval);
      }
    }, 50) // 50毫秒改变一次
  }


  return (
    <div>
      <CSSTransition in={true}
        classNames="home-container"
        timeout={500}
        //元素退出时删除DOM元素
        // unmountOnExit={true}
        appear>
        <div className="story-container">
          <Header />
          <div className="oni-page-content">
            <div className="oni-text">
              <div className="oni-textone" >
                <p className="text-title">THE UNPRECEDENTED SPREAD</p>
                <p className="text-col">Full of Blood </p>
                <div className="line"></div>
                <p className="text-dec">A group of NFT creatures that spread rapidly throughout the metaverse<br />
                  They crave for blood and love being different<br />
                  A new spark will be born!</p>
              </div>
              <div className="oni-textwo" >
                <p className="text-title">ONI CLUB</p>
                <p className="text-col-two">About Oni</p>
                <div className="line-two"></div>
                <p className="text-dec-two">Oni contains a collection of 10,000 avatars. Own it and spread it! Let it infect every corner of the meta-universe. Oni is your new identity in the metaverse. Let's stain our hands with blood!</p>
              </div>
              <div className="oni-textwo" >
                <p className="text-col-two">Who are we?</p>
                <div className="line-two"></div>
                <p className="text-dec-two">Oni Core Team is from the future Tokyo, Japan. an engineering team with expertise in encryption, technology. We aim for more people to donate blood. Let's take over the world together!</p>
              </div>
            </div>
            <div className="oni-imgs" >
              <img src={welcome} className='footer-man' alt="" />
              <div className="oni-animation-img">
                <img className="oni-handlegrafyti" src={handlegrafyti} alt="" id="handlegrafyti" />
              </div>

            </div>
          </div>
          <div className="crow-box">
            <img src={far} className='crow-img crow-far' alt="" />
            <img src={nearly} className='crow-img crow-nearly' alt="" />
            <img src={middle} className='crow-img crow-middle' alt="" />
          </div>
          <Footer />
        </div>
      </CSSTransition>
    </div>
  );
}

export default Story;
