import './index.sass';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import homebg from '../../asset/imgs/Mobilehome/homebg.gif';
// import new1 from "../../asset/imgs/blogs/blog1/new1.png";
// import newimg from "../../asset/imgs/blogs/newimg.png";
import storybg from "../../asset/imgs/Mobilehome/storybg.png";
import storyhome from "../../asset/imgs/Mobilehome/storyhome.png";
// import roadmapbg from "../../asset/imgs/Mobilehome/roadmapbg.png";
import newimg from "../../asset/imgs/blogs/newimg.png";

import Maket1 from "../../asset/imgs/about/Maket1.png";
import Maket2 from "../../asset/imgs/about/Maket2.png";
import Maket3 from "../../asset/imgs/about/Maket3.png";
import Maket4 from "../../asset/imgs/about/Maket4.png";
import Maket5 from "../../asset/imgs/about/Maket5.png";
import Maket6 from "../../asset/imgs/about/Maket6.png";
import Maket7 from "../../asset/imgs/about/Maket7.png";
import aboutbg from "../../asset/imgs/Mobilehome/aboutbg.png";
import homeimg from '../../asset/imgs/home/homeimg.png';
import { useState, useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/css';
import { useConcent } from 'concent';
import { useNavigate } from "react-router-dom";
import { Pagination } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#2a333e',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});
const RoadmapDatecop = ({ title, imgurl }) => {
  return (
    <div className='content-box'>
      <p dangerouslySetInnerHTML={{ __html: title }} />
      <div className='contentBox-img'>
        <img src={imgurl} alt=""></img></div>
    </div>
  )
}
const MobileHome = () => {
  const [mySwiper, setMySwiper] = useState(null)
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0)
  const [blogListShow, setBlogListShow] = useState([])
  const [blogList, setBlogList] = useState([])
  const [pageSize] = useState(2)
  const [currenPage, setCurrenPage] = useState(1)
  const blogs = useConcent('blogs');
  const roadmap = useConcent('roadmap');
  useEffect(() => {
    const { clientWidth } = document.body;
    if (clientWidth > 750) {
      navigate('/home');
    }
    const swiper = new Swiper("#swiper-container1", {
      paginationClickable: false,
      autoplayDisableOnInteraction: false,
      direction: 'vertical'
    });
    setMySwiper(swiper);
    setBlogList(blogs.state.blogs);
    setPageCount(Math.ceil(blogs.state.blogs.length / pageSize))
    setBlogListShow(blogs.state.blogs.slice(0, pageSize))
  }, [navigate, blogs, pageSize]);
  const jumpToPage = (number) => {
    mySwiper?.slideTo(number);
  };


  const jumpToNewPage = (url, index) => {

    let offset = (currenPage - 1) * pageSize;
    index = index + offset
    blogs.setState({ currentBlog: index })
    window.location.replace(url + "?index=" + index)
  };
  const cutDec = (val) => {
    return val.substr(0, 135) + "..."
  }
  //PageNO 是页数
  const pageChange = (e, pageNo) => {
    //offset 页面显示的第一个对象的下标值
    //pageSize 是每个页面显示几个列表 57行定义的
    let offset = (pageNo - 1) * pageSize;
    setCurrenPage(pageNo)
    setBlogListShow((offset + pageSize >= blogList.length) ? blogList.slice(offset, blogList.length) : blogList.slice(offset, offset + pageSize))
  }
  return (
    <div className="mobile-home-container">
      <Header jumpToPage={jumpToPage} />
      <div className="swiper-wrap">
        <div className="swiper-container" id="swiper-container1">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="Video-box">
                <img className="video-img" controls src={homebg} autoPlay loop muted alt=''></img>
                <img className="homeimg" src={homeimg} alt=""></img>
              </div>
            </div>
            <div className="swiper-slide Newspage-slide">
              <div className="News-page">
                <div className="News-content-left" >
                  <p className="News-content-title">BLOGS</p>
                  <div className="News-content-line"></div>
                  <p className="News-content-dec">Talk about everything</p>
                  {blogListShow?.map((row, index) => (
                    <div key={index}>
                      <div className="News-box" onClick={() => { jumpToNewPage("/#/blogsdetile", index); }} >
                        <div className="News-box-img">
                          <img src={row.img} alt=""></img>
                        </div>
                        <div className="News-box-dec" >
                          <div className="News-box-dec-tar">
                            <img src={row.tar_title} alt=""></img>
                            <p className="News-time">{row.time}</p>
                          </div >
                          <p className="News-title">{row.title}</p>
                          <p className="News-dec" dangerouslySetInnerHTML={{ __html: cutDec(row.dec) }}></p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <ThemeProvider theme={theme}>
                  <Pagination shape="rounded" onChange={pageChange} count={pageCount} size="small" color="primary" />
                </ThemeProvider>
                <div className="News-content-right" >
                  <img src={newimg} alt=""></img>
                </div>
              </div>

            </div>


            <div className="swiper-slide">
              <div className="oni-page-content">
                <div className="crow-box">
                  <img src={storybg} className='crow-img crow-far' alt="" />
                </div>
                <div className="oni-text">
                  <div className="oni-textone" >
                    <p className="text-title">THE UNPRECEDENTED SPREAD</p>
                    <p className="text-col">Full of Blood </p>
                    <div className="line"></div>
                    <p className="text-dec">A group of NFT creatures that spread rapidly throughout the metaverse<br />
                      They crave for blood and love being different<br />
                      A new spark will be born!</p>
                  </div>
                  <div className="oni-textwo" >
                    <p className="text-title">ONI CLUB</p>
                    <p className="text-col-two">About Oni</p>
                    <div className="line-two"></div>
                    <p className="text-dec-two">Oni contains a collection of 10,000 avatars. Own it and spread it! Let it infect every corner of the meta-universe. Oni is your new identity in the metaverse. Let's stain our hands with blood!</p>
                  </div>
                  <div className="oni-textwo" >
                    <p className="text-col-two">Who are we?</p>
                    <div className="line-two"></div>
                    <p className="text-dec-two">Oni Core Team is from the future Tokyo, Japan. an engineering team with expertise in encryption, technology. We aim for more people to donate blood. Let's take over the world together!</p>
                  </div>
                </div>
                <div className="oni-imgs" >
                  <img src={storyhome} className='footer-man' alt="" />
                </div>
              </div>
            </div>
            <div className="swiper-slide swiper-roadmap">
              <div className="roadmap-box">
                <div className='roadmap-img' >
                  <div className='roadmap-content' >
                    <p className='title'>ROADMAP</p>
                    <p className='line'></p>
                    {roadmap.state?.roadmap?.map((row, index) => (
                      <RoadmapDatecop key={index} title={row.title} imgurl={row.imgurl}></RoadmapDatecop>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="Maketplace-content">
                <div className='Maketplace-bg'>
                  <img src={aboutbg} alt=''></img>
                </div>
                <div className="Maketplace-text">
                  <p className="Maketplace-title">ABOUT ONI NFT</p>
                  <div className="line-two"></div>
                  <p className="Maketplace-dec">Oni contains a collection of 10,000 avatars. Own it and spread it! Let it infect every corner of the meta-universe. Oni is your new identity in the metaverse. Let's stain our hands with blood!</p>
                  <div className="Maketplace-text-down">
                    <div className="Maketplace-text-left">
                      <img src={Maket1} alt=''></img>
                      <img src={Maket2} alt=''></img>
                      <img src={Maket3} alt=''></img>
                      <img src={Maket4} alt=''></img>
                      <img src={Maket5} alt=''></img>
                      <img src={Maket6} alt=''></img>
                      <img src={Maket7} alt=''></img>
                    </div>
                    <div className="Maketplace-text-right">
                      <div className="right-box">
                        <p className="right-title">Apparel</p>
                        <p className="right-text">Japanese style originated by OniClub, with a strong sense of cyber future.</p>
                        <div className="right-line"></div>
                      </div>
                      <div className="right-box">
                        <p className="right-title">Mask</p>
                        <p className="right-text">Thousands of ghosts and thousands of faces, which is not to hide their identity, but to reveal themselves.</p>
                        <div className="right-line"></div>
                      </div>
                      <div className="right-box">
                        <p className="right-title">Weapon</p>
                        <p className="right-text">Everyone has a weapon to conquer the metaverse, bloodied or rusted.</p>
                        <div className="right-line"></div>
                      </div>
                      <div className="right-box">
                        <p className="right-title">Hair Style</p>
                        <p className="right-text">A good haircut is the key to stay in style.</p>
                        <div className="right-line"></div>
                      </div>
                      <div className="right-box" >
                        <p className="right-title">Body</p>
                        <p className="right-text">Can you feel that? This body is full of genes that are eager to donate blood.</p>
                        <div className="right-line"></div>
                      </div>
                      <div className="right-box">
                        <p className="right-title">Tooth</p>
                        <p className="right-text">Ready to suck blood?</p>
                        <div className="right-line"></div>
                      </div>
                      <div className="right-box">
                        <p className="right-title">Red Pupil</p>
                        <p className="right-text">Yes, it is Oni!</p>
                        <div className="right-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide"></div>
            <div className="swiper-slide"></div>
            <div className="swiper-slide"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default MobileHome;
