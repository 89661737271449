import './index.sass';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import vampire1 from '../../asset/imgs/roadmap/vampire1.png';
import vampire2 from '../../asset/imgs/roadmap/vampire2.png';
import vampire3 from '../../asset/imgs/roadmap/vampire3.png';
import vampire4 from '../../asset/imgs/roadmap/vampire4.png';
import { useEffect } from 'react';
import { useConcent } from 'concent';
import { CSSTransition } from 'react-transition-group';
import './transition.css';

const RoadmapDatecop = ({ title, imgurl }) => {
  return (
    <div className='content-box'>
      <p dangerouslySetInnerHTML={{ __html: title}} /> 
      <div className='contentBox-img'>
        <img src={imgurl}></img></div>
    </div>
  )
}
const Roadmap = () => {
  useEffect(() => {
    console.log(1111);
    const { clientHeight } = document.body;
    const pageHeight = clientHeight / 4;
    // const roadmapContent = document.querySelector(".roadmap-content");
    const vampire1Dom = document.querySelector("#vampire1");
    let leftPiont = 100;
    let rightPiont = 150;
    const vampire2Dom = document.querySelector("#vampire2");
    const vampire3Dom = document.querySelector("#vampire3");
    // const vampire4Dom = document.querySelector("#vampire4");
    document.body.addEventListener('wheel', (event) => {
      const { screenY, pageY } = event
      const scrollLength = pageY - screenY;
      console.log('clientHeight', clientHeight);
      console.log('offsetY', pageY - screenY);
      // 第一屏 left 从100 ～ -50  right 从 150 ～0
      if (scrollLength < pageHeight) {
        const percent = (pageY + 80 - screenY) / pageHeight;
        rightPiont = 150 * (1 - percent);
        leftPiont = rightPiont - 50;
        vampire1Dom.style.clipPath = `polygon(0% 0%, 100% 0%, 100% ${rightPiont}%, 0% ${leftPiont}%)`;
      }
      // 第二屏
      if ((scrollLength < 2 * pageHeight) && (scrollLength > pageHeight)) {
        const percent = (pageY + 80 - screenY - pageHeight) / pageHeight;
        rightPiont = 150 * (1 - percent);
        leftPiont = rightPiont - 50;
        vampire2Dom.style.clipPath = `polygon(0% 0%, 100% 0%, 100% ${rightPiont}%, 0% ${leftPiont}%)`;
      }
      // 第三屏
      if ((scrollLength > 2 * pageHeight) && (scrollLength < 3 * pageHeight)) {
        const percent = (pageY + 80 - screenY - (2 * pageHeight)) / pageHeight;
        rightPiont = 150 * (1 - percent);
        leftPiont = rightPiont - 50;
        vampire3Dom.style.clipPath = `polygon(0% 0%, 100% 0%, 100% ${rightPiont}%, 0% ${leftPiont}%)`;
      }

    })
  })
  const { state } = useConcent('roadmap');
  return (
    <div><CSSTransition in={true}
      classNames="roadmap-container"
      timeout={500}
      //元素退出时删除DOM元素
      // unmountOnExit={true}
      appear>
      <div className="roadmap-container">
        <Header />
        <div className="roadmap-content">
          <div className='content-left'>
            <div className='img-box' id='vampire1'><img src={vampire1} alt='' /> </div>
            <div className='img-box' id='vampire2'><img src={vampire2} alt='' /> </div>
            <div className='img-box' id='vampire3'><img src={vampire3} alt='' /> </div>
            <div className='img-box' id='vampire4'><img src={vampire4} alt='' /> </div>
          </div>
          <div className='content-right'>
            <div className='roadmap-line'>
              <div className='road-map-text'>
                <p className='title'>ROADMAP</p>
                <p className='line'></p>
                {state.roadmap?.map((row, index) => (
                  <RoadmapDatecop key={index} title={row.title} imgurl={row.imgurl}></RoadmapDatecop>
                ))}
              </div>
              {/* <img src={arrow} alt="" className='arrow-img' /> */}
            </div>
          </div>
        </div>
        <Footer type='white' />
      </div>
    </CSSTransition>
    </div>
  )
};

export default Roadmap;
