import ReactDOM from 'react-dom';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createHashHistory } from 'history';
// store相关
import { Provider } from 'react-redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import immerPlugin from '@rematch/immer';
import Home from "./pages/home";
import MobileHome from "./pages/mobilehome";
import Blogs from "./pages/blogs";
import Roadmap from "./pages/roadmap";
import About from "./pages/about";
import Story from "./pages/story";
import Music from "./components/music";
import Blogsdetile from "./pages/blogsdetile";
import './common.sass';
import { clearContextIfHot } from "concent";
import "./runConcent";
clearContextIfHot();
renderReactApp();

// 渲染入口模块
function renderReactApp() {
  const history = createHashHistory();
  const middleware = routerMiddleware(history);

  const store = init({
    models: {},
    plugins: [
      createLoadingPlugin({}),
      immerPlugin(),
    ],
    redux: {
      reducers: {
        router: routerReducer,
      },
      middlewares: [middleware],
    },
  });
  const { clientWidth } = document.body;

  const App = () => (
    <>
      <Provider store={store}>
        <HashRouter>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/mobilehome" element={<MobileHome />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogsdetile" element={<Blogsdetile />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/about" element={<About />} />
            <Route path="/story" element={<Story />} />
            <Route path="/" element={<Navigate replace to="/home" />} />
          </Routes>
        </HashRouter>
      </Provider>
      {clientWidth > 750 && <Music />}
    </>
  );

  ReactDOM.render(<App />, document.getElementById('root'));
}
