import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './Header.sass';
import logow from "../asset/imgs/header/logow.png";
import menuIcon from "../asset/imgs/header/menuIcon.png";
import closeIcon from "../asset/imgs/header/closeIcon.png";
import mFooterBg from "../asset/imgs/header/mFooterBg.png";
import IS from "../asset/imgs/header/IS.png";
import TW from "../asset/imgs/header/TW.png";
import redLine from "../asset/imgs/header/redLine.png";
import Music from "./music";


const Header = ({ jumpToPage }) => {
  const navigate = useNavigate("");
  const location = useLocation({});
  const [isShowModal, setIsShowModal] = useState(false);
  useEffect(() => {
    const { clientWidth } = document.body;
    if (clientWidth <= 750) {
      if (location.pathname === '/blogsdetile') {
        return;
      }
      navigate('/mobilehome');
    }
  }, [location, navigate])
  const { clientWidth } = document.body;
  return clientWidth > 750
    ? (<div className="oni-header">
      <div className="oni-logo">
        <img onClick={() => {
          navigate('/home');
        }} src={logow} alt='' /></div>
      <div className="oni-menu">
        <div onClick={() => {
          navigate('/home');
        }}>HOME</div>
        <div data-transition="slide" onClick={() => {
          navigate('/blogs');
        }}>BLOGS</div>
        <div onClick={() => {
          navigate('/story');
        }}>STORY</div>
        <div onClick={() => {
          navigate('/roadmap');
        }}>ROADMAP</div>
        <div onClick={() => {
          // navigate('/roadmap');
        }}>
          RANK
          <div className="m-red-line rank">
            <img src={redLine} alt='' />
          </div>
        </div>
        <div onClick={() => {
          // navigate('/home');
        }}>
          SHOP
          <div className="m-red-line rank">
            <img src={redLine} alt='' />
          </div>
        </div>
        <div onClick={() => {
          navigate('/about');
        }}>ABOUT</div>
        <div className="sns-box">
          {/* <img src={discord} alt='' onClick={() => {
            window.open("https://www.instagram.com/oniclubnft/");
          }}></img> */}
          <img src={IS} alt='' onClick={() => {
            window.open("https://www.instagram.com/oniclubnft/");
          }}></img>
          <img src={TW} alt='' onClick={() => {
            window.open("https://twitter.com/OniclubNFTs");
          }}></img>
        </div>

      </div>
    </div>)
    : (<div className="oni-header">
      <div className="oni-logo">

        <img onClick={() => {
          navigate('/home');
        }} src={logow} alt='' />
      </div>
      <div className="oni-menu" onClick={() => { setIsShowModal(true) }}>
        <span>MENU</span>
        <img src={menuIcon} alt='' />
      </div>
      <div className="m-menu-modal" style={{ visibility: isShowModal ? 'unset' : 'hidden' }}>
        <div className="oni-header">
          <div className="oni-logo">
            <img onClick={() => {
              navigate('/home');
            }} src={logow} alt='' />
          </div>
          <div className="oni-menu" onClick={() => { setIsShowModal(false) }}>
            <span>CLOSE</span>
            <img src={closeIcon} alt='' />
          </div>
        </div>
        <div className="oni-m-content" >
          <Music />
          <div className="oni-m-menu">
            <div onClick={() => {
              setIsShowModal(false);
              if (jumpToPage) {
                return jumpToPage(0);
              }
              navigate('/home');
            }}>HOME</div>
            <div onClick={() => {
              setIsShowModal(false);
              if (jumpToPage) {
                return jumpToPage(1);
              }
              navigate('/blogs');
            }}>BLOGS</div>
            <div onClick={() => {
              setIsShowModal(false);
              if (jumpToPage) {
                return jumpToPage(2);
              }
              navigate('/story');
            }}>STORY</div>
            <div onClick={() => {
              setIsShowModal(false);
              if (jumpToPage) {
                return jumpToPage(3);
              }
              navigate('/roadmap');
            }}>ROADMAP</div>
            <div onClick={() => {
              // setIsShowModal(false);
              // navigate('/roadmap');
            }}>
              RANK
              <div className="m-red-line rank">
                <img src={redLine} alt='' />
              </div>
            </div>
            <div onClick={() => {
              // setIsShowModal(false);
              // navigate('/home');
            }}>
              SHOP
              <div className="m-red-line rank">
                <img src={redLine} alt='' />
              </div>
            </div>
            <div onClick={() => {
              setIsShowModal(false);
              if (jumpToPage) {
                return jumpToPage(4);
              }
              navigate('/about');
            }}>ABOUT</div>
          </div>
        </div>
        <div className="sns-box">
          {/* <img src={discord} alt='' onClick={() => {
            window.open("https://www.instagram.com/oniclubnft/");
          }}></img> */}
          <img src={IS} alt='' onClick={() => {
            window.open("https://www.instagram.com/oniclubnft/");
          }}></img>
          <img src={TW} alt='' onClick={() => {
            window.open("https://twitter.com/OniclubNFTs");
          }}></img>
        </div>
        <div className="menu-bg">
          <img src={mFooterBg} alt='' />
        </div>
      </div>
    </div >)
}
export default Header;
