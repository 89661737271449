import { useState } from "react";
import './index.sass';
import './transition.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { CSSTransition } from 'react-transition-group';
import { useConcent } from 'concent';
import { GetQueryString } from "../../utils/url"
const Blogsdetile = () => {
  const [clientWidth] = useState(document.body.clientWidth);
  const { state } = useConcent('blogs');
  const currentBlog = !GetQueryString()["index"] ? 0 : Number(GetQueryString()["index"])
  const blog = state.blogs[state.blogs.length - 1 < currentBlog ? 0 : currentBlog]
  return (
    <div>
      <CSSTransition in={true}
        classNames="blogsdetile-container"
        timeout={500}
        //元素退出时删除DOM元素
        // unmountOnExit={true}
        appear>
        <div className="blogsdetile-container">
          <Header />
          <div className="Newstwo">
            <p className="Newstwo-title">{blog?.title}</p>
            <div className="News-content-line"></div>
            {clientWidth >= 750 ? (
              <img src={blog?.slogan} alt=""></img>
            ) : <img src={blog?.sloganmob} alt=""></img>}
            {/* {clientWidth >= 750 ? (
              <div> {blog?.slogan.map((row, index) => (
                <img src={row.slogan} alt=""></img>
              ))}</div>
            ) : <div> {blog?.sloganmob.map((row, index) => (
              <img src={row.sloganmob} alt=""></img>
            ))}</div>} */}
            <p className="Newstwo-dec" dangerouslySetInnerHTML={{ __html: blog?.dec }}>
            </p>
            <div className="Newstwo-tar">
              <ul>
                {blog?.tar.map((row, index) => (
                  <li key={index}>{row}</li>
                ))}
              </ul>
            </div>
            <p className="Newstwo-support">{blog.titsupportle}</p>
            {/* <div className="Newstwo-sns">
              {blog?.buttons.map((row, index) => (
                <button key={index}>
                  <img src={row.img} alt=""></img>
                  <p>{row.content}</p>
                </button>
              ))}
            </div> */}
            {clientWidth >= 750 ? (
              null
            ) : <br />}
          </div>
          <Footer />
        </div>
      </CSSTransition>
    </div>
  )
};

export default Blogsdetile;
