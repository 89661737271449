import './index.sass';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import newimg from "../../asset/imgs/blogs/newimg.png";
import { CSSTransition } from 'react-transition-group';
import { useConcent } from 'concent';
import './transition.css';
const Blogs = () => {
  const { state, setState } = useConcent('blogs');
  const jumpToNewPage = (url, index) => {
    setState({ currentBlog: index })
    window.location.replace(url + "?index=" + index)
  };
  const cutDec = (val) => {
    return val.substr(0, 160) + "..."
  }
  return (
    <div>
      <CSSTransition in={true}
        classNames="blogs-container"
        timeout={500}
        //元素退出时删除DOM元素
        // unmountOnExit={true}
        appear>
        <div className="blogs-container">
          <Header />
          <div className="News-page">
            <div className="News-content-left" >
                  <p className="News-content-title">BLOGS</p>
                  <div className="News-content-line"></div>
                  <p className="News-content-dec">Talk about everything</p>
                  {state.blogs?.map((row, index) => (
                <div key={index}>
                  <div className="News-box" onClick={() => { jumpToNewPage("/#/blogsdetile", index); }} >
                    <div className="News-box-img">
                      <img src={row.img} alt=""></img>
                    </div>
                    <div className="News-box-dec" >
                      <div className="News-box-dec-tar">
                        <img src={row.tar_title}></img>
                        <p className="News-time">{row.time}</p>
                      </div >
                      <p className="News-title">{row.title}</p>
                      <p className="News-dec" dangerouslySetInnerHTML={{ __html: cutDec(row.dec) }}></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="News-content-right" >
              <img src={newimg} alt=""></img>
            </div>
          </div>
          <Footer />
        </div>
      </CSSTransition>
    </div>
  )
};

export default Blogs;
