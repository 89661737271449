import './Footer.sass';

const Footer = ({ type = 'black' }) => {
  const { clientWidth } = document.body;

  return (
    clientWidth > 750
      ? <div className={`oni-footer ${type === 'white' ? 'white-footer' : ''} `}>
        <div>
        </div>
        <div>
        </div>
        <div>
          ©2022 ONI Club️
        </div>
      </div>
      : null
  )
}
export default Footer;
